import React, { useEffect } from "react";
import ProductsDetailsContainer from "../../components/products/ProductsDetailsContainer";
import Header from "../../components/home/Header";
import Footer from "../../components/home/Footer";

const ProductsDetailsPage = () => {
  useEffect(() => {
    window.scroll("scroll", function () {
      window.scroll(0, 0);
    });
  }, []);
  return (
    <div>
      <Header />
      <ProductsDetailsContainer />
      <Footer />
    </div>
  );
};

export default ProductsDetailsPage;
