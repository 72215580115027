import React from "react";
import { useTranslation } from "react-i18next";

const CountUp = () => {
  const { t } = useTranslation();

  const dataCountUp = [
    {
      title: t("number-of-consultations"),
      val: "241",
    },
    {
      title: t("our-products"),
      val: 4,
    },
    // {
    //   title: "تطبيقات الهاتف",
    //   val: "5",
    // },
    // {
    //   title: "تطبيقات الويب",
    //   val: "12",
    // },
    {
      title: t("erb-Charts"),
      val: "7",
    },
  ];
  return (
    <div className="container">
      <div
        className="bg-white rounded-4 style-shadow"
        style={{ transform: "translateY(-60px)" }}
      >
        <div className="row p-4 ">
          {dataCountUp.map((item, i) => (
            <div key={i} className="col m-2">
              <div className="d-flex justiify-content-center align-items-center flex-column">
                <div className="d-flex justiify-content-between align-items-center">
                  <div className="m-1">
                    <img
                      src="../../images/trend.png"
                      style={{ width: 40, height: 40 }}
                      alt=""
                    />
                  </div>
                  <div
                    className="fw-bold fs-4"
                    style={{ color: "var(--primary)" }}
                  >
                    {item.val}+
                  </div>
                </div>
                <div className="fw-bold fs-6" style={{ color: "var(--gray)" }}>
                  {item.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountUp;
