import React, { useEffect } from "react";
import Header from "../../components/home/Header";
import Footer from "../../components/home/Footer";
import BlogsDetailsContainer from "../../components/blogs/BlogsDetailsContainer";

const BlogsDetailsPage = () => {
  useEffect(() => {
    window.scroll("scroll", function () {
      window.scroll(0, 0);
    });
  }, []);
  return (
    <div>
      <Header />
      <BlogsDetailsContainer />
      <Footer />
    </div>
  );
};

export default BlogsDetailsPage;
