/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { ProductsData, dataProducts } from "../home/Products";
import { useParams } from "react-router-dom";
import FormInput from "../utility/FormInput";
import FormInputTextArea from "../utility/FormInputTextArea";
import ButtonLoading from "../utility/ButtonLoading";
import ButtonRoundedFill from "../utility/ButtonRoundedFill";
import emailjs from "@emailjs/browser";
import Notify from "../utility/Notify";
import { useTranslation } from "react-i18next";

const ProductsDetailsContainer = () => {
  const { t } = useTranslation();
  const { dataProducts } = ProductsData();

  const [data, setData] = useState(dataProducts);

  const idRoute = useParams();
  const form = useRef();

  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_4wm4j8h",
        "template_unn2fei",
        form.current,
        "qvXEW1USUZMOwQxs4"
      )
      .then(
        (result) => {
          setLoading(false);
          Notify("تم إرسال طلب ", "done");
          // console.log(result.text);
        },
        (error) => {
          setLoading(false);
          Notify("هناك مشكلة في إرسال الطلب ", "error");

          // console.log(error.text);
        }
      );
  };
  return (
    <div className="container">
      <div className="col-12 col-lg-8 m-auto ">
        <div className="modal-header">
          <h1
            className="modal-title fs-4 text-center w-100 fw-bold"
            id="exampleModalLabel"
            style={{ color: "var(--primary)" }}
          >
            {data[idRoute.id].appName}
          </h1>
        </div>
        <div className="modal-body">
          <div className="my-3">
            <div className="my-2">
              <span className="fw-bold">نبذة عن المنتج</span>
              {" : "}
              <span>{data[idRoute.id].description}</span>
            </div>{" "}
            <div className="my-2">
              <span className="fw-bold">تفاصيل المنتج</span>
              {" : "}
              <span>{data[idRoute.id].details}</span>
            </div>{" "}
            <div className="my-2">
              <span className="fw-bold">السعر</span>
              {" : "}
              <span>{data[idRoute.id].price} ريال</span>
            </div>
          </div>
          {/* FORMS */}
          <form
            ref={form}
            onSubmit={sendEmail}
            className="col-12 border-radius-contact-us-1 bg-white d-flex justify-content-center align-items-center flex-column p-4"
          >
            <div className="col-12  my-1">
              <FormInput
                type={"text"}
                onChange={() => null}
                // value={""}
                label={"الأسم"}
                name={"from_name"}
                placeholder={"الأسم"}
                idAndfor={"validationCustomIdNo"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="col-12  my-1">
              <FormInput
                type={"text"}
                onChange={() => null}
                // value={""}
                label={"رقم الجوال"}
                name={"from_phone"}
                placeholder={"رقم الجوال"}
                idAndfor={"validationCustomIdNo"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="col-12  my-1">
              <FormInput
                type={"text"}
                onChange={() => null}
                // value={""}
                label={"البريد الإلكتروني"}
                name={"from_email"}
                placeholder={"البريد الإلكتروني"}
                idAndfor={"validationCustomIdNo"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="col-12  my-1">
              <FormInput
                type={"text"}
                onChange={() => null}
                value={data[idRoute.id].appName}
                label={"المنتج"}
                name={"from_serv"}
                placeholder={"المنتج"}
                idAndfor={"validationCustomIdNo"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="col-12  my-1">
              <FormInputTextArea
                // onChange={handelChangeForms}
                // value={""}
                label={"رسالتك"}
                placeholder={"رسالتك"}
                name={"from_msg"}
                idAndfor={"validationCustomIdPrivate"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>{" "}
            <div className="d-flex justify-content-center mt-4">
              {loading ? (
                <ButtonLoading width={180} />
              ) : (
                <ButtonRoundedFill title={"إرسال"} width={180} />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductsDetailsContainer;
