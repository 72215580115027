import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
import ReactLoading from "react-loading";

import { I18nextProvider } from "react-i18next";
import i18n from "./lang/i18n";
import { Suspense } from "react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <Suspense
        fallback={
          <div
            className=" d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <ReactLoading
              type={"bars"}
              color={"var(--primary)"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        }
      >
        <App />
      </Suspense>
    </React.StrictMode>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
