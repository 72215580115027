import React from "react";
import { Link } from "react-router-dom";
import HeaderTitle from "../utility/HeaderTitle";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const BLogsContainer = () => {
  const { t } = useTranslation();

  const { dataBlogs } = BlogsData();
  return (
    <div className="container">
      <HeaderTitle title={t("blog")} />

      <div className=" mt-5 pt-3">
        <div className="row gy-4">
          {dataBlogs.map((item, i) => (
            <div key={i} className="col-12 col-md-6 col-lg-4 ">
              <div
                className={`p-3 text-center text-dark rounded-3  d-flex flex-column justify-content-between position-relative style-shadow`}
                style={{
                  background: "#fff",
                  // height: 250,
                }}
              >
                <div
                  className="font-size-title"
                  style={{ color: "var(--primary)" }}
                >
                  {item.title}
                </div>
                <div
                  className="font-size-subTitle mt-3"
                  style={
                    {
                      // fontSize: 13,
                    }
                  }
                >
                  {item.details.slice(0, 100)}...
                </div>
                <Link
                  to={`/blogs-details/${i}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    type="priamry"
                    className=" m-3 mb-0 fw-bold text-white"
                    style={{ background: "var(--primary)", boxShadow: "none" }}
                  >
                    {t("more")}
                  </Button>
                </Link>
                {/* <Link
                  to={`/blogs-details/${i}`}
                  style={{
                    color: "var(--primary)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  className="btn-more"
                >
                  المزيد
                </Link> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BLogsContainer;

export const BlogsData = () => {
  const { t } = useTranslation();
  const dataBlogs = [
    {
      title: t("ERB implementation stages"),
      details: t("desc-ERB implementation stages"),
      items: [
        {
          title: t("items-ERP-implementation-stages.one"),
          items: [
            {
              title: "تحديد الأهداف",
              description:
                "تحديد الأهداف المحددة لتنفيذ نظام ERP والفوائد المتوقعة.",
            },
            {
              title: "تقييم الحالة الحالية",
              description:
                "تحليل البنية التحتية الحالية والعمليات والمشكلات المحتملة التي يمكن أن يحل النظام ERP محلها.",
            },
            {
              title: "تحديد البائع",
              description: "اختيار مزود النظام ERP الذي يلبي احتياجات المؤسسة.",
            },
          ],
        },
        {
          title: t("items-ERP-implementation-stages.two"),
          items: [
            {
              title: "تحديد المتطلبات",
              description:
                "تحديد المتطلبات الوظيفية والتقنية التي يجب أن يلبيها النظام ERP.",
            },
            {
              title: "تخطيط العمليات",
              description:
                "وضع خطط لتنفيذ النظام ERP وتكامله مع العمليات الحالية.",
            },
          ],
        },
        {
          title: t("items-ERP-implementation-stages.three"),
          items: [
            {
              title: "تخصيص النظام",
              description: "تخصيص النظام ERP وفقًا لمتطلبات المؤسسة.",
            },
            {
              title: "تطوير واختبار",
              description:
                "تطوير المكونات المخصصة واختبارها لضمان أنها تعمل بشكل صحيح.",
            },
            {
              title: "التدريب",
              description:
                "تقديم التدريب لفرق العمل على كيفية استخدام وإدارة النظام الجديد.",
            },
          ],
        },
        {
          title: t("items-ERP-implementation-stages.four"),
          items: [
            {
              title: "تحويل البيانات",
              description: "نقل البيانات من الأنظمة القديمة إلى النظام الجديد.",
            },
            {
              title: "التجربة النهائية",
              description:
                "إجراء اختبارات نهائية للتحقق من أن النظام يعمل بشكل صحيح وفقًا للمتطلبات.",
            },
          ],
        },
        {
          title: t("items-ERP-implementation-stages.five"),
          items: [
            {
              title: "تشغيل النظام",
              description: "بدء تشغيل النظام ERP واستخدامه في العمليات الحية.",
            },
            {
              title: "الصيانة والتحسين",
              description:
                "مراقبة أداء النظام وإجراء التحسينات والتعديلات عند الضرورة.",
            },
          ],
        },
        {
          title: t("items-ERP-implementation-stages.six"),
          items: [
            {
              title: "قياس الأداء",
              description:
                "قياس أداء النظام ERP وتقييم مدى تحقيقه للأهداف المحددة.",
            },
            {
              title: "تحسين المشروع",
              description:
                "استنادًا إلى التقييم، اتخاذ إجراءات لتحسين أداء النظام ومواجهة التحديات",
            },
          ],
        },
      ],
    },
    {
      title: t("Stages of implementing an online store"),
      details: t("desc-Stages of implementing an online store"),
      question: "إليك مراحل تنفيذ متجر إلكتروني",
      items: [
        {
          title: t("items-Stages-of-implementing-an-online-store.one"),
          items: [
            {
              title: "تحديد الهدف",
              description:
                "تحديد أهداف إنشاء المتجر الإلكتروني، سواء كان ذلك للبيع عبر الإنترنت أو تعزيز الحضور الرقمي.",
            },
            {
              title: "تحديد الميزة التنافسية",
              description:
                "تحديد ما يميز متجرك عن منافسيك وكيف ستقدم قيمة للعملاء.",
            },
            {
              title: "اختيار المنصة",
              description:
                "اختيار منصة تجارة إلكترونية مناسبة مثل Shopify، WooCommerce، Magento، أو غيرها.",
            },
          ],
        },
        {
          title: t("items-Stages-of-implementing-an-online-store.two"),
          items: [
            {
              title: "تصميم واجهة المستخدم",
              description:
                "تصميم وتنفيذ واجهة المستخدم الجذابة وسهلة الاستخدام.",
            },
            {
              title: "تطوير الهيكل والوظائف",
              description:
                "بناء هيكل الموقع وتطوير الوظائف اللازمة لعرض المنتجات وإتمام عمليات الشراء.",
            },
          ],
        },
        {
          title: t("items-Stages-of-implementing-an-online-store.three"),
          items: [
            {
              title: "رفع المنتجات",
              description:
                "إضافة وصف وصور للمنتجات مع تصنيفها وسعرها ومزيد من التفاصيل.",
            },
            {
              title: "إنشاء المحتوى",
              description:
                "إنشاء صفحات إضافية مثل صفحة 'من نحن'، 'سياسة الخصوصية'، وغيرها.",
            },
          ],
        },
        {
          title: t("items-Stages-of-implementing-an-online-store.four"),
          items: [
            {
              title: "تكامل طرق الدفع",
              description:
                "توفير خيارات دفع آمنة ومتنوعة للعملاء مثل البطاقات الائتمانية وبوابات الدفع الإلكتروني.",
            },
            {
              title: "تكامل أنظمة الشحن",
              description:
                "توفير حسابات الشحن وتكامل مع شركات الشحن لتوفير تقديرات الشحن وتتبع الطلبيات.",
            },
          ],
        },
        {
          title: t("items-Stages-of-implementing-an-online-store.five"),
          items: [
            {
              title: "اختبار الويب",
              description:
                "اختبار الموقع عبر مختلف المتصفحات والأجهزة للتأكد من أنه يعمل بشكل صحيح.",
            },
            {
              title: "اختبار عملية الشراء",
              description:
                "اختبار العملية الكاملة للشراء للتأكد من أنها سلسة وبدون مشاكل.",
            },
          ],
        },

        {
          title: t("items-Stages-of-implementing-an-online-store.six"),
          items: [
            {
              title: "تجهيز للإطلاق",
              description:
                "تجهيز الموقع للإطلاق من حيث إعدادات النطاق، والاختبار الأخير، والضبط النهائي.",
            },
            {
              title: "حملة التسويق الإلكتروني",
              description:
                "بدء حملات ترويجية عبر وسائل التواصل الاجتماعي، الإعلانات المدفوعة، والحملات البريدية.",
            },
          ],
        },
        {
          title: t("items-Stages-of-implementing-an-online-store.seven"),
          items: [
            {
              title: "تتبع ومراقبة",
              description:
                "متابعة أداء المتجر من حيث المبيعات والزوار ومعدلات التحويل.",
            },
            {
              title: "الصيانة",
              description:
                "إجراء التحديثات الدورية وإصلاح المشاكل التقنية التي تظهر.",
            },
          ],
        },
        {
          title: t("items-Stages-of-implementing-an-online-store.eight"),
          items: [
            {
              title: "تحليل البيانات",
              description:
                "تحليل بيانات المبيعات والسلوك لاستخلاص رؤى حول أداء المتجر وسلوك العملاء.",
            },
            {
              title: "التحسين المستمر",
              description:
                "تحسين أداء الموقع وتجربة العملاء استنادًا إلى البيانات والرؤى.",
            },
          ],
        },
      ],
    },
    {
      title: t("Evaluating the organization's IT team"),
      details: t("desc-Evaluating the organization's IT team"),
      question:
        "إليك بعض الخطوات والجوانب التي يمكن تضمينها في تقييم فريق تقنية المعلومات في المنظمة",
      items: [
        {
          title: t("items-Evaluating-the-organization's-IT-team.one"),
          items: [
            {
              title: "مراجعة الأداء الفردي لأعضاء الفريق",
              description: "من حيث الجودة والكفاءة والالتزام.",
            },
            {
              title: "تقدير القدرات التقنية والمهارات الفردية",
              description: "وكيفية تطويرها.",
            },
            {
              title: "تحديد التحديات التي يواجهها الأفراد وكيفية دعمهم",
              description: "",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.two"),
          items: [
            {
              title: "تقييم كفاءة العمليات التقنية المستخدمة في المنظمة",
              description: "",
            },
            {
              title: "مراجعة تنفيذ المشاريع السابقة",
              description: "من حيث الجدول الزمني والجودة والتكلفة.",
            },
            {
              title:
                "تحديد أية تحسينات يمكن تطبيقها لتحسين تنفيذ المشاريع المستقبلية",
              description: "",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.three"),
          items: [
            {
              title:
                "تقييم مدى تفاعل فريق تقنية المعلومات مع أقسام وأفراد آخرين في المنظمة",
              description: "",
            },
            {
              title:
                "مراجعة كفاءة التواصل الداخلي والتبادل المعرفي بين الأعضاء",
              description: "",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.four"),
          items: [
            {
              title: "مراجعة تدابير الأمان والحماية",
              description: "التي تم تنفيذها ومدى فعاليتها.",
            },
            {
              title:
                "تحديد مدى الامتثال للمعايير واللوائح القانونية والتنظيمية",
              description: "",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.five"),
          items: [
            {
              title:
                "مراجعة مدى كفاءة دعم تقنية المعلومات للمستخدمين وحل المشكلات",
              description: "",
            },
            {
              title: "تحديد مدى فاعلية عمليات الصيانة الوقائية والتصليحية",
              description: "",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.six"),
          items: [
            {
              title:
                "مراجعة مدى توفر استراتيجية للتطوير التقني واستخدام تكنولوجيا جديدة",
              description: "",
            },
            {
              title:
                "تحديد مدى الاستفادة من الابتكارات التقنية لتحسين العمليات",
              description: "",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.seven"),
          items: [
            {
              title: "تقييم أداء قادة فريق تقنية المعلومات",
              description: "من حيث توجيه الفريق واتخاذ القرارات الاستراتيجية.",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.eight"),
          items: [
            {
              title: "مقارنة أداء فريق تقنية المعلومات",
              description: "مع الأهداف والمعايير المحددة.",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.nine"),
          items: [
            {
              title:
                "إجراء استطلاعات أو مقابلات مع أعضاء الفريق ومستخدمي التقنية",
              description: "لجمع آرائهم وتقييم مدى رضاهم.",
            },
          ],
        },
        {
          title: t("items-Evaluating-the-organization's-IT-team.ten"),
          items: [
            {
              title: "استنادًا إلى نتائج التقييم",
              description:
                "وضع خطة لتنفيذ توصيات التحسين في أنشطة فريق تقنية المعلومات.",
            },
          ],
        },
      ],
    },
    {
      title: t("Determine the organization's technical needs"),
      details: t("desc-Determine the organization's technical needs"),
      items: [
        {
          title: t("items-Determine-the-organization's-technical-needs.one"),
          items: [
            {
              title: "فهم أهداف واحتياجات المؤسسة أو المشروع",
              description: "",
            },
            {
              title: "تحليل العمليات الحالية وتحديد المشاكل والفجوات",
              description: "",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.two"),
          items: [
            {
              title: "تحديد الأهداف التي تريد تحقيقها باستخدام التكنولوجيا",
              description:
                "سواء كانت تحسين الكفاءة، زيادة الإنتاجية، تحسين تجربة العملاء، أو غيرها.",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.three"),
          items: [
            {
              title:
                "استنادًا إلى تحليل الاحتياجات والأهداف، تحديد الأنظمة والأدوات التقنية الممكنة",
              description: "",
            },
            {
              title:
                "ابحث عن الحلول المبتكرة والتكنولوجيات الناشئة التي يمكن أن تدعم أهدافك",
              description: "",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.four"),
          items: [
            {
              title: "تقييم تكلفة تنفيذ وصيانة الحلول المقترحة",
              description: "",
            },
            {
              title: "تحديد الفوائد المتوقعة من استخدام الحلول التقنية",
              description:
                "مثل توفير الوقت، زيادة الإنتاجية، تحسين تجربة العملاء، إلخ.",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.five"),
          items: [
            {
              title:
                "التحقق من توافق الحلول المقترحة مع البنية التحتية الحالية للمؤسسة",
              description: "",
            },
            {
              title:
                "التأكد من إمكانية تكامل الحلول المختارة مع الأنظمة القائمة",
              description: "",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.six"),
          items: [
            {
              title:
                "اختيار الحلول التقنية التي تتناسب مع احتياجات وأهداف المؤسسة",
              description: "",
            },
            {
              title: "النظر في تقييمات الموردين والاستشاريين للحلول المختارة",
              description: "",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.seven"),
          items: [
            {
              title:
                "وضع خطة تنفيذ تفصيلية تشمل الجداول الزمنية والمهام المحددة والمسؤوليات",
              description: "",
            },
            {
              title: "تحديد الميزانية المخصصة لتنفيذ وصيانة الحلول",
              description: "",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.eight"),
          items: [
            {
              title: "تنفيذ الحلول التقنية وفقًا للخطة المحددة",
              description: "",
            },
            {
              title:
                "مراقبة تقدم التنفيذ وتقييم أداء الحلول وفقًا للمعايير المحددة",
              description: "",
            },
          ],
        },
        {
          title: t("items-Determine-the-organization's-technical-needs.nine"),
          items: [
            {
              title: "استمر في مراقبة أداء الحلول وجمع البيانات لتقييم كفاءتها",
              description: "",
            },
            { title: "ابحث عن فرص التحسين والتطوير المستمر", description: "" },
          ],
        },
      ],
    },
    {
      title: t("Effective e-marketing"),
      details: t("desc-Effective e-marketing"),
      items: [
        {
          title: t("items-Effective e-marketing.one"),
          items: [
            {
              title: "تحديد الأهداف الرئيسية",
              description:
                "زيادة المبيعات، زيادة حركة المرور، تعزيز الوعي بالعلامة التجارية",
            },
            {
              title: "تحديد الجمهور المستهدف",
              description: "فهم احتياجاتهم وتفضيلاتهم",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.two"),
          items: [
            {
              title: "اختيار قنوات التواصل المناسبة",
              description:
                "وسائل التواصل الاجتماعي، البريد الإلكتروني، المدونات، الإعلانات المدفوعة",
            },
            {
              title: "تطوير خطة تسويقية شاملة",
              description: "محتوى متنوع ومؤثر وجذاب للجمهور",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.three"),
          items: [
            {
              title: "تحسين الموقع لمحركات البحث",
              description:
                "استخدام الكلمات الرئيسية المناسبة وتحسين محتوى الموقع",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.four"),
          items: [
            {
              title: "بناء وجود قوي على منصات التواصل الاجتماعي",
              description: "نشر محتوى متنوع وجذاب يشجع على التفاعل والمشاركة",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.five"),
          items: [
            {
              title: "بناء قائمة بريدية",
              description: "تقديم محتوى مفيد وقيم عبر البريد الإلكتروني",
            },
            {
              title: "تصميم رسائل بريدية ملهمة وجاذبة",
              description: "تعزيز فعالية التفاعل مع الجمهور",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.six"),
          items: [
            {
              title: "استخدام الإعلانات المدفوعة",
              description: "Google Ads وFacebook Ads للوصول إلى جمهور مستهدف",
            },
            {
              title: "تحديد ميزانية إعلانية",
              description: "مراقبة وضبط الإعلانات حسب الحاجة",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.seven"),
          items: [
            {
              title: "إنشاء محتوى ذو قيمة",
              description: "حل مشكلات الجمهور وتلبية احتياجاتهم",
            },
            {
              title:
                "استخدام مقاطع فيديو ومقالات ودليل المستخدم وتقارير ومحتوى تعليمي",
              description: "",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.eight"),
          items: [
            {
              title: "استخدام أدوات التحليل مثل Google Analytics",
              description: "قياس أداء حملات التسويق وفهم سلوك الجمهور",
            },
            {
              title: "تحليل البيانات",
              description: "تحديد ما يعمل وما يحتاج إلى تحسين",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.nine"),
          items: [
            {
              title: "الاستجابة السريعة للاستفسارات والتعليقات",
              description: "عبر مختلف القنوات",
            },
            {
              title: "بناء علاقات مؤثرة مع العملاء",
              description: "من خلال التواصل الفعال",
            },
          ],
        },
        {
          title: t("items-Effective e-marketing.ten"),
          items: [
            {
              title: "تحسين استراتيجيات التسويق",
              description: "استنادًا إلى التحليلات وردود الفعل",
            },
            {
              title: "مواكبة التطورات التكنولوجية والاتجاهات",
              description: "في مجال التسويق الإلكتروني",
            },
          ],
        },
      ],
    },
  ];
  return { dataBlogs };
};
