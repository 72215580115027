import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ShareAltOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const ProductItem = ({ id, path, logo, appName, description, price, clc }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-4 style-shadow">
      <div className="d-flex align-items-center flex-column bg-white rounded-top-4 p-3 px-4">
        {/* logo */}
        <div>
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: "100%", height: 100 }}
          />
        </div>
      </div>
      <div
        className="my-2 mt-3  p- rounded-bottom-4 text-white position-relative"
        style={{
          backgroundImage: "linear-gradient(to top, #743C91 0%, #9b66b7 100%)",
        }}
      >
        <div className="Triangles"></div>
        <div className="p-3 pt-5">
          {/* app name */}
          <div className=" fw-bold h4" style={{ color: "var(--primaary)" }}>
            {appName}
          </div>

          <span className="">{description}</span>

          <div className="d-flex justify-content-center mt-4">
            <Link to={path} target="_blanck" style={{ textDecoration: "none" }}>
              <Button
                type="priamry"
                className=" mx-2 fw-bold"
                style={{ background: "#fff", boxShadow: "none" }}
                icon={<ShareAltOutlined />}
              >
                {t("visiting-site")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
