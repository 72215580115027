import React from "react";
import HeaderTitle from "../utility/HeaderTitle";
import ProductItem from "./ProductItem";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();
  const { dataProducts } = ProductsData();
  return (
    <div className="container ">
      <HeaderTitle title={t("our-products")} />

      <div className="row gy-4 mt-5">
        {dataProducts.map((item, i) => (
          <div key={i} className="col-12 col-sm-6 col-lg-4 col-xl-3">
            <ProductItem
              id={i}
              path={item.path}
              logo={item.logo}
              appName={item.appName}
              description={item.description}
              details={item.details}
              price={item.price}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;

export const ProductsData = () => {
  const { t } = useTranslation();

  const dataProducts = [
    {
      path: "https://maris.osoolsys.sa/",
      logo: "../../images/logo-maris.png",
      appName: t("Maris"),
      description: t("desc-Maris"),
    },
    {
      path: "https://ticketyy.osoolsys.sa/",
      logo: "../../images/logo-tekity.png",
      appName: t("Tekity"),
      description: t("desc-Tekity"),
    },
    {
      path: "https://crmm.osoolsys.sa/",
      logo: "../../images/logo-crm.png",
      appName: t("CRM"),
      description: t("desc-CRM"),
    },
    {
      path: "https://softguidetech.com/",
      logo: "../../images/logo-SGT.png",
      appName: t("SGT-soft-guide-technology"),
      description: t("desc-SGT-soft-guide-technology"),
    },
  ];
  return { dataProducts };
};
