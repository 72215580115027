import React from "react";

const ButtonLoading = ({ width, title = "جاري الإرسال" }) => {
  return (
    <div>
      <button
        style={{
          width: width,
        }}
        className="hover-btn-pill"
        // className="btn btn-primary"
        type="button"
        // disabled
      >
        <span
          className="spinner-border spinner-border-sm"
          aria-hidden="true"
        ></span>{" "}
        <span role="status">{title}</span>
      </button>
    </div>
  );
};

export default ButtonLoading;
