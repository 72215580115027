import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/home/HomePage";
import ServicesDetailsPage from "./pages/serviceDetails/ServicesDetailsPage";
import AboutDetailspage from "./pages/about/AboutDetailspage";
import BlogsPage from "./pages/blogs/BlogsPage";
import BlogsDetailsPage from "./pages/blogs/BlogsDetailsPage";
import ContactUsPage from "./pages/contactUs/ContactUsPage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ProductsDetailsPage from "./pages/products/ProductsDetailsPage";

function App() {
  return (
    <div className="App style-sidebar-scroll-radius ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/serv-details/:id" element={<ServicesDetailsPage />} />
          <Route path="/about-details" element={<AboutDetailspage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs-details/:id" element={<BlogsDetailsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/prodcuts/:id" element={<ProductsDetailsPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
