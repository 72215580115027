import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const dataMedia = [
    {
      icon: "logo-twitter",
      link: "https://twitter.com/Osoolsys",
    },
    {
      icon: "logo-whatsapp",
      link: "tel:+966 55 432 4345",
    },
    {
      icon: "mail",
      link: "mailto:info@osoolsys.sa",
    },
  ];
  let dataNow = new Date();

  const media = [
    {
      icon: "../../images/icon-footer-location.png",
      title: "KSA - Riyadh 13244-2284 Qurtubah Dist 7546 - Unit No 1110",
    },
    {
      icon: "../../images/icon-footer-phone-call.png",
      title: "phone : +966 55 432 4345",
    },
    {
      icon: "../../images/icon-footer-tel.png",
      title: "Tel : 0530435697",
    },
    {
      icon: "../../images/icon-footer-mail.png",
      title: "Email : K.alzahrani@osoolsys.sa",
    },
  ];
  return (
    <div
      className="container-fulid mt-5 p-0 m-0"
      style={{ backgroundColor: "var(--primary)" }}
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center flex-column flex-md-row py-4">
          <div className=" d-flex justify-content-center position-relative">
            <img src="../../images/bg-white-logo.png" alt="" />
            <img
              src="../../images/logo.png"
              className="position-absolute"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
              alt=""
            />
          </div>
          <div className="d-flex align-items-start flex-column mt-4 mt-md-0">
            {media.map((item, i) => (
              <div
                key={i}
                className="d-flex align-items-center justify-content-center flex-column my-1 w-100"
              >
                <span
                  className="rounded-circle p-3 d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#8343a3",
                    width: 40,
                    height: 40,
                  }}
                >
                  <img
                    src={item.icon}
                    style={{ width: 22, height: 22 }}
                    alt="icon"
                  />
                </span>
                <span
                  className="mt-2 text-white text-center d-flex "
                  style={{ fontSize: 13 }}
                >
                  {" "}
                  {item.title}
                </span>
              </div>
            ))}
          </div>
          <div className=" mt-4 mt-md-0 d-flex align-items-center justify-content-center flex-column ">
            <div className="fw-bold mb-3 text-white">{t("We are happy to communicate with you")}</div>
            <div className="style-footer-media">
              {dataMedia.map((item, i) => (
                <Link
                  key={i}
                  className={`style-footer-media-link style-footer-media-link-${
                    i + 1
                  }`}
                  to={item.link}
                >
                  <ion-icon name={item.icon}></ion-icon>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="fs-4 fw-bold text-white my-3">{t("Success Partners")}</div>
        <div style={{ width: 200 }} className="bg-white p-2 rounded-4">
          <img
            style={{ maxWidth: "100%" }}
            src={"../../images/logo-SGT.png"}
            alt={"logo-SGT"}
          />
        </div>
      </div>
      <hr className="text-white" />
      <div className="text-center text-white pb-4">
        {" "}
        {t("all rights are save")} © {dataNow.getFullYear()}
      </div>
    </div>
  );
};

export default Footer;
