import React, { useEffect } from "react";
import Header from "../../components/home/Header";
import Footer from "../../components/home/Footer";

import ServicesDetailsContainer from "../../components/services/ServicesDetailsContainer";

const ServicesDetailsPage = () => {
  // const [first, setfirst] = useState(true);
  useEffect(() => {
    window.scroll("scroll", function () {
      window.scroll(0, 0);
    });
  }, []);
  return (
    <div>
      <Header />
      <ServicesDetailsContainer />
      <Footer />
    </div>
  );
};

export default ServicesDetailsPage;
