import React from "react";
import HeaderTitle from "../utility/HeaderTitle";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const MyServices = () => {
  const { t } = useTranslation();

  const { dataServ } = MyServicesData();
  return (
    <div className="container">
      <HeaderTitle title={t("advisory-services")} />
      <div className=" mt-5 pt-3">
        <div className="row gy-4">
          {dataServ.map((item, i) => (
            <div key={i} className="col-12 col-md-6 col-lg-4 ">
              <div
                className={`p-3 text-center text-dark rounded-4  d-flex flex-column justify-content-between position-relative style-shadow`}
                style={{
                  background: "#fff",
                  // height: 250,
                }}
              >
                <div
                  className=" font-size-title"
                  style={{ color: "var(--primary)" }}
                >
                  {item.title}
                </div>
                <div
                  className="font-size-subTitle mt-3"
                  style={
                    {
                      // fontSize: 15,
                    }
                  }
                >
                  {item.details.split("").slice(0, 100).join("")} {"..."}
                </div>
                <Link
                  to={`/serv-details/${i}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    type="priamry"
                    className=" m-3 mb-0 fw-bold text-white"
                    style={{ background: "var(--primary)", boxShadow: "none" }}
                    // icon={<ShareAltOutlined />}
                  >
                    {t("more")}
                  </Button>
                </Link>
                {/* <Link
                  to={`/serv-details/${i}`}
                  style={{
                    color: "var(--primary)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  className="btn-more"
                >
                  المزيد
                </Link> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyServices;

export const MyServicesData = () => {
  const { t } = useTranslation();

  const dataServ = [
    {
      title: t("Technical consultations"),
      details: t("desc-technical-consultations"),
      items: [
        {
          title: t("assess-existing-technologies"),
          description: t("desc-assess-existing-technologies"),
        },
        {
          title: t("choosing-the-right-techniques"),
          description: t("desc-choosing-the-right-techniques"),
        },
        {
          title: t("project-planning"),
          description: t("desc-project-planning"),
        },
        {
          title: t("solving-technical-problems"),
          description: t("desc-solving-technical-problems"),
        },
        {
          title: t("software-development"),
          description: t("desc-software-development"),
        },
        {
          title: t("information-security"),
          description: t("desc-information-security"),
        },
        {
          title: t("Web-technology"),
          description: t("desc-Web-technology"),
        },
        {
          title: t("digital-transformation-strategies"),
          description: t("desc-digital-transformation-strategies"),
        },
      ],
      goal: "",
    },
    {
      title: t("ERP-systems-projects"),
      details: t("desc-ERP-systems-projects"),
      items: [
        {
          title: t("needs-assessment"),
          description: t("desc-needs-assessment"),
        },
        {
          title: t("select-solutions"),
          description: t("desc-select-solutions"),
        },
        {
          title: t("design-and-Planning"),
          description: t("desc-design-and-Planning"),
        },
        {
          title: t("execute-and-configure"),
          description: t("desc-execute-and-configure"),
        },
        {
          title: t("users-training"),
          description: t("desc-users-training"),
        },
        {
          title: t("Post-implementation-support"),
          description: t("desc-Post-implementation-support"),
        },
        {
          title: t("improve-processes"),
          description: t("desc-improve-processes"),
        },
      ],
      goal: "بشكل عام، تهدف خدمات الاستشارة في أنظمة موارد المؤسسات إلى مساعدة المؤسسات في تحقيق فوائد كبيرة من تنفيذ نظام ERP، بما في ذلك زيادة الكفاءة وتحسين الإنتاجية وتبسيط العمليات التجارية.",
    },
    {
      title: t("cloud-computing"),
      details: t("desc-cloud computing"),
      items: [
        {
          title: t("Needs-assessment"),
          description: t("desc-Needs-assessment"),
        },
        {
          title: t("choose-cloud-services"),
          description: t("desc-choose-cloud-services"),
        },
        {
          title: t("cloud-architecture-design"),
          description: t("desc-cloud-architecture-design"),
        },
        {
          title: t("solution-Integration"),
          description: t("desc-solution-Integration"),
        },
        {
          title: t("data-and-information-security"),
          description: t("desc-data-and-information-security"),
        },
        {
          title: t("Improve-performance"),
          description: t("desc-Improve-performance"),
        },
        {
          title: t("cost-and-management"),
          description: t("desc-cost-and-management"),
        },
        {
          title: t("migration-and-mobility-strategies"),
          description: t("desc-migration-and-mobility-strategies"),
        },
      ],
      goal: "تهدف خدمات الاستشارة في مجال الحوسبة السحابية إلى مساعدة العملاء في استخدام الحوسبة السحابية بشكل فعال وملائم لأهدافهم التقنية والأعمال، مما يساعدهم على تحقيق التحسينات في الأداء وتكاليف التشغيل",
    },
    {
      title: t("Website-design-consultation"),
      details: t("desc-Website-design-consultation"),
      items: [
        {
          title: t("Website-design-consultation"),
          description: t("desc-Website-design-consultation"),
        },
        {
          title: t("Needs-assessment"),
          description: t("desc-Needs-assessment"),
        },
        {
          title: t("user-experience"),
          description: t("desc-user-experience"),
        },
        {
          title: t("Page-and-content-design"),
          description: t("desc-Page-and-content-design"),
        },
        {
          title: t("development-techniques"),
          description: t("desc-development-techniques"),
        },
        {
          title: t("Site-integration"),
          description: t("desc-Site-integration"),
        },
        {
          title: t("hosting-strategies"),
          description: t("desc-hosting-strategies"),
        },
        {
          title: t("Site-security"),
          description: t("desc-Site-security"),
        },
        {
          title: t("improve-performance"),
          description: t("desc-improve-performance"),
        },
        {
          title: t("providing-strategic-direction"),
          description: t("desc-providing-strategic-direction"),
        },
      ],
      goal: "تهدف خدمات استشارة تصميم المواقع الإلكترونية إلى تحقيق موقع ويب متميز يلبي احتياجات العميل ويتفق مع رؤيته وأهدافه، بالإضافة إلى تقديم تجربة مميزة للمستخدمين.",
    },
    {
      title: t("consultancy-in-building-electronic-applications"),
      details: t("dedsc-consultancy-in-building-electronic-applications"),
      items: [
        {
          title: t("analyse-the-idea"),
          description: t("desc-analyse-the-idea"),
        },
        {
          title: t("development-strategy"),
          description: t("desc-development-strategy"),
        },
        {
          title: t("UI-design"),
          description: t("desc-UI-design"),
        },
        {
          title: t("application-development"),
          description: t("desc-application-development"),
        },
        {
          title: t("test-and-experiment"),
          description: t("desc-test-and-experiment"),
        },
        {
          title: t("deployment-and-distribution"),
          description: t("desc-deployment-and-distribution"),
        },
        {
          title: t("marketing-strategies"),
          description: t("desc-marketing-strategies"),
        },
        {
          title: t("improve-Performance"),
          description: t("desc-Improve-performance"),
        },
        {
          title: t("continue-and-update"),
          description: t("desc-continue-and-update"),
        },
        {
          title: t("app-Security"),
          description: t("desc-app-Security"),
        },
      ],
      goal: "تهدف خدمات استشارة تطبيقات الهواتف الذكية إلى مساعدة العملاء في تطوير تطبيقات متميزة وذات جودة عالية تلبي احتياجات المستخدمين وتحقق الأهداف المرجوة.",
    },
    {
      title: t("digita-transformation"),
      details: t("desc-digita-transformation"),
      items: [
        {
          title: t("digita-transformation"),
          description: t("desc-digita-transformation"),
        },
        {
          title: t("evaluation-of-current-situation"),
          description: t("desc-evaluation-of-current-situation"),
        },
        {
          title: t("Strategic-development"),
          description: t("desc-Strategic-development"),
        },
        {
          title: t("Choosing-techniques"),
          description: t("desc-Choosing-techniques"),
        },
        {
          title: t("developing-digital-culture"),
          description: t("desc-developing-digital-culture"),
        },
        {
          title: t("improve-Processes"),
          description: t("desc-improve-Processes"),
        },
        {
          title: t("change-management"),
          description: t("desc-change-management"),
        },
        {
          title: t("data-security-and-privacy"),
          description: t("desc-data-security-and-privacy"),
        },
        {
          title: t("Improve-customer-experience"),
          description: t("desc-Improve-customer-experience"),
        },
        {
          title: t("Measurement-and-evaluation"),
          description: t("desc-Measurement-and-evaluation"),
        },
      ],
      goal: "تهدف خدمات استشارة التحول الرقمي إلى مساعدة المؤسسات في تحويل أعمالها وعملياتها بشكل ناجح نحو العصر الرقمي، وتحقيق الفوائد من التكنولوجيا الحديثة والتحسين المستمر",
    },
  ];
  return { dataServ };
};
