import { toast } from "react-toastify";

const Notify = (msg, type) => {
  if (type === "done") {
    toast.success(msg, {
      theme: "colored",
    });
  } else if (type === "warn") {
    toast.warn(msg, {
      theme: "colored",
    });
  } else if (type === "error") {
    toast.error(msg, {
      theme: "colored",
    });
  }
};

export default Notify;
