import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import LinkRoundedFill from "../utility/LinkRoundedFill";
import { useTranslation } from "react-i18next";
import { MyServicesData } from "../home/MyServices";

const ServicesDetailsContainer = () => {
  const idRoute = useParams();
  // console.log(idRoute);
  const { t } = useTranslation();
  const { dataServ } = MyServicesData();

  // useEffect(() => {
  //   window.addEventListener("scroll", function () {
  //     window.scrollTo(1000, 0);
  //   });
  //   return () => {
  //     window.removeEventListener("scroll", function () {
  //       window.scrollTo(200, 1000);
  //     });
  //   };
  // }, []);

  const [dataServSelected] = useState(dataServ[idRoute.id]);

  // console.log(dataServ[idRoute.id]);
  return (
    <div className="container mt-4" style={{ minHeight: "50vh" }}>
      {/* title */}
      <h1 className="text-center " style={{ color: "var(--primary)" }}>
        {dataServSelected.title}
      </h1>
      {/* details */}
      <div className="mt-4" style={{ color: "var(--gray)" }}>
        {dataServSelected.details}
      </div>
      {/* question */}
      {/* <div className="my-3 text-dark fw-bold">
        {dataServSelected.question} :{" "}
      </div> */}
      {/* items */}
      <div className="row gy-3 mt-3">
        {dataServSelected.items.map((item, i) => (
          <div key={i} className="col-12 col-sm-6  col-md-4 ">
            <div
              className=" p-2 rounded-3 style-shadow"
              style={{ background: "var(--primary)" }}
            >
              <div
                key={i}
                className="my-2 d-flex flex-column align-items-center"
              >
                <span
                  className="text-center font-size-title"
                  style={{ color: "#fff" }}
                >
                  {item.title}
                </span>
                <span
                  className="text-center mt-2 font-size-subTitle p-2"
                  style={{ color: "#fff", opacity: 0.8 }}
                >
                  {item.description}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div>
        {dataServSelected.items.map((item, i) => (
          <div key={i} className="my-2">
            <span className="fw-bold" style={{ color: "var(--primary)" }}>
              {item.title} :{" "}
            </span>
            <span className="" style={{ color: "var(--gray)" }}>
              {item.description}
            </span>
          </div>
        ))}
      </div> */}
      {/* goal */}
      {/* <div
        className="my-3 mt-4 text-center"
        style={{ color: "var(--primary)" }}
      >
        {dataServSelected.goal}
      </div> */}

      {/* button join */}
      <div className="d-flex justify-content-center align-items-center mt-5 flex-column">
        <LinkRoundedFill to="/contact-us" title={t("join")} width={180} />
        <div className="mt-2">
          <Link
            to={"/"}
            style={{
              color: "var(--primary)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            className="btn-more"
          >
            {t("back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServicesDetailsContainer;
