import React from "react";

const HeaderTitle = ({ title }) => {
  return (
    <div className="container-header-title mt-5">
      <div className="header-title">
        <div className="title px-4">{title}</div>
        <div className="line">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTitle;
