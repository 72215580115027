import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <div
      className="container-fluid position-relative"
      style={{ backgroundColor: "var(--primary)" }}
    >
      <div className="container d-flex flex-column p-3 p-md-5 flex-md-row">
        <div className=" d-flex justify-content-center position-relative">
          <img src="../../images/bg-white-logo.png" alt="" />
          <img
            src="../../images/logo.png"
            className="position-absolute"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            alt=""
          />
        </div>
        {/* text */}
        {/* <Fade center> */}
        <div className=" mx-0 mx-md-4  text-white mt-4 mt-md-2">
          <h1 className=" fw-bold" style={{ color: "var(--primary-color)" }}>
            {t("about-us")}
          </h1>
          <div className="  mt-3  text-opacity-75 lh-lg">
            {t("desc-about-us")}{" "}
          </div>
        </div>
        {/* </Fade> */}
      </div>
    </div>
  );
};

export default About;
