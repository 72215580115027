/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import { useTranslation } from "react-i18next";
const Header = () => {
  const { t, i18n } = useTranslation();

  const dataLanguages = [
    {
      title: t("langs.ar"),
      flag: "ar",
      code: "ar",
    },
    {
      title: t("langs.en"),
      flag: "en",
      code: "en",
    },
  ];

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);

    window.location.reload();
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.language]);
  const routes = [
    {
      title: t("home"),
      path: "/",
    },
    {
      title: t("about-us"),
      path: "/about-details",
    },

    {
      title: t("contact-us"),
      path: "/contact-us",
    },
  ];

  const mores = [
    {
      title: t("blog"),
      path: "/blogs",
    },
  ];

  const [isOpen, setOpen] = useState(false);
  return (
    <nav className="navbar navbar-expand-lg text-dark p-0">
      <div className="container ">
        <h6 className="">
          <Link
            className="nav-item-link text-dark "
            style={{ textDecoration: "none" }}
            to={"/"}
          >
            <img
              src="../../images/logo.png"
              alt="logo"
              className="mt-2"
              style={{
                height: 70,
              }}
            />
          </Link>
        </h6>
        <button
          className="navbar-toggler p-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Hamburger toggled={isOpen} toggle={setOpen} color="var(--primary)" />

          {/* <span className="navbar-toggler-icon"></span> */}
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex justify-content-between flex-column flex-lg-row w-100">
            <div></div>
            <div className="navbar-nav mb-2 mb-lg-0">
              {routes.map((item, i) => (
                <li key={i} className="nav-item m-2 mx-3">
                  <Link
                    className="nav-item-link text-dark"
                    style={{ textDecoration: "none" }}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
              <div className="nav-item  nav-item-link ">
                <div className=" dropdown m-2 mx-3">
                  <Link
                    className="nav-item-link dropdown-toggle  text-dark "
                    style={{ textDecoration: "none" }}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="mx-1">{t("other")}</span>
                  </Link>

                  <div
                    className="dropdown-menu p-0 border-0  shadow-sm "
                    style={{
                      left: -60,
                      top: 35,
                      borderRadius: 20,
                    }}
                  >
                    <div className="position-relative ">
                      {mores.length === 0 ? null : (
                        <div
                          className="position-absolute"
                          style={{
                            // background: "red",
                            borderWidth: 7,
                            borderColor:
                              "transparent transparent white transparent",
                            borderStyle: "solid",
                            left: "50%",

                            top: -7,
                            transform: "translate(-50%,-50%)",
                          }}
                        ></div>
                      )}
                      {mores.map((item, i) => (
                        <div key={i}>
                          <div className="">
                            <Link
                              className="dropdown-item text-center"
                              style={{
                                borderRadius:
                                  mores.length === 1
                                    ? "5px"
                                    : i === 0
                                    ? "5px 5px 0 0"
                                    : i === mores.length - 1
                                    ? "0 0 5px 5px"
                                    : "0 0 0 0",
                              }}
                              to={item.path}
                            >
                              {item.title}
                            </Link>
                          </div>
                          {i === mores.length - 1 ? null : (
                            <hr className="dropdown-divider p-0 m-0 opacity-25" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* lang */}
            <div className="nav-item  nav-item-link ">
              <div className=" dropdown m-2 mx-3 d-inline d-lg-block">
                <Link
                  className="nav-item-link dropdown-toggle  text-dark"
                  style={{ textDecoration: "none" }}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="mx-1">{t("langs.title")}</span>
                </Link>

                <div
                  className="dropdown-menu p-0 border-0  shadow-sm inline"
                  style={{
                    left: -60,
                    top: 35,
                    borderRadius: 20,
                  }}
                >
                  <div className="position-relative ">
                    {dataLanguages.length === 0 ? null : (
                      <div
                        className="position-absolute"
                        style={{
                          // background: "red",
                          borderWidth: 7,
                          borderColor:
                            "transparent transparent white transparent",
                          borderStyle: "solid",
                          left: "50%",

                          top: -7,
                          transform: "translate(-50%,-50%)",
                        }}
                      ></div>
                    )}
                    {dataLanguages.map((item, i) => (
                      <div key={i}>
                        <div className="">
                          <Link
                            className="dropdown-item text-center"
                            onClick={() => handleChangeLanguage(item.code)}
                            style={{
                              borderRadius:
                                mores.length === 1
                                  ? "5px"
                                  : i === 0
                                  ? "5px 5px 0 0"
                                  : i === mores.length - 1
                                  ? "0 0 5px 5px"
                                  : "0 0 0 0",
                            }}
                            to={item.path}
                          >
                            {item.title}
                          </Link>
                        </div>
                        {i === mores.length - 1 ? null : (
                          <hr className="dropdown-divider p-0 m-0 opacity-25" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
