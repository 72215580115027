import React from "react";

const ButtonRoundedFill = ({ title, onclick, onchange, value, width }) => {
  return (
    <button
      style={{
        width: width,
      }}
      className="hover-btn-pill"
      onClick={onclick}
      onChange={onchange}
      value={value}
    >
      {title}
    </button>
  );
};

export default ButtonRoundedFill;
