/* eslint-disable no-unused-vars */
import React from "react";
import HeaderTitle from "../utility/HeaderTitle";
import { useTranslation } from "react-i18next";

const AboutDetailsContainer = () => {
  const { t } = useTranslation();

  const dataAbout = [
    {
      title: t("Technical consulting"),
      description: t("desc-Technical consulting"),
    },
    {
      title: t("Analysis and evaluation"),
      description: t("desc-Analysis and evaluation"),
    },
    {
      title: t("Choose solutions"),
      description: t("desc-Choose solutions"),
    },
    {
      title: t("Planning and implementing projects"),
      description: t("desc-Planning and implementing projects"),
    },
    {
      title: t("Systems integration"),
      description: t("desc-Systems integration"),
    },
    {
      title: t("Information security"),
      description: t("desc-Information security"),
    },
    {
      title: t("improve the performance"),
      description: t("desc-improve the performance"),
    },
    {
      title: t("Training and development of human resources"),
      description: t("desc-Training and development of human resources"),
    },
    {
      title: t("Strategic consulting"),
      description: t("desc-Strategic consulting"),
    },
    {
      title: t("Follow technical developments"),
      description: t("desc-Follow technical developments"),
    },
  ];

  const dataVision = [
    {
      icon: "../../images/icon-our-task.png",
      title: t("mission"),
      subtitle: t("our-mission"),
    },
    {
      icon: "../../images/vition.png",
      title: t("vision"),
      subtitle: t("our-vision"),
    },
  ];
  const goals = [
    t("our-goals-content.one"),
    t("our-goals-content.two"),
    t("our-goals-content.three"),
    t("our-goals-content.four"),
    t("our-goals-content.five"),
    t("our-goals-content.sex"),
    t("our-goals-content.seven"),
  ];

  const teamsWork = [
    {
      img: "../../images/img-user-1.png",
      job: "مطور React",
    },
    {
      img: "../../images/img-user-default.png",
      job: "مطور Laravel",
    },
    {
      img: "../../images/img-user-default.png",
      job: "تسويق رقمي",
    },
    {
      img: "../../images/img-user-default.png",
      job: "CEO IT",
    },
  ];
  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      {/* title */}
      <HeaderTitle title={t("about-us")} />
      <div className="mt-5">
        {/* description */}
        <div className="" style={{ color: "var(--gray)" }}>
          {t("desc-about-us")}
        </div>
        {/* question */}
        {/* <div className="my-3 text-dark fw-bold">
          تشمل خدمات شركات الاستشارات في مجال تقنية المعلومات ما يلي :{" "}
        </div> */}
        {/* items */}
        <div className="row gy-3 my-2">
          {dataAbout.map((item, i) => (
            <div key={i} className="col-12 col-sm-6  col-md-4  ">
              <div
                className=" p-2 rounded-3 style-shadow"
                style={{ background: "var(--primary)" }}
              >
                <div
                  key={i}
                  className="my-2 d-flex flex-column align-items-center"
                >
                  <span
                    className="text-center font-size-title"
                    style={{ color: "#fff" }}
                  >
                    {item.title}
                  </span>
                  <span
                    className="text-center mt-2 font-size-subTitle"
                    style={{ color: "#fff", opacity: 0.8 }}
                  >
                    {item.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div>
          {dataAbout.map((item, i) => (
            <div key={i} className="my-2">
              <span className="fw-bold" style={{ color: "var(--primary)" }}>
                {item.title} :{" "}
              </span>
              <span className="" style={{ color: "var(--gray)" }}>
                {item.description}
              </span>
            </div>
          ))}
        </div> */}
        {/* goal */}
        {/* <div
          className="my-3 mt-4 text-center"
          style={{ color: "var(--primary)" }}
        >
          بشكل عام، تهدف شركات الاستشارات في مجال تقنية المعلومات إلى توجيه
          ومساعدة العملاء في الاستفادة الأمثل من التكنولوجيا لتحقيق التحسين
          والتطوير في عملياتهم وأعمالهم.
        </div> */}
      </div>
      <HeaderTitle title={t("vision")} />
      <div className="row gy-4 mt-5">
        {dataVision.map((item, i) => (
          <div key={i} className="col-12 col-md-6 col-lg-4 ">
            <div
              className={`p-3 text-center  text-white rounded-3 style-scroll-radius `}
              style={{
                background: "var(--primary)",
                height: 300,
              }}
            >
              <div>
                <div className=" d-flex justify-content-center position-relative">
                  <img
                    src={item.icon}
                    style={{ width: 70, height: 70 }}
                    alt=""
                  />
                </div>
              </div>
              <div className="fw-bold my-2 fs-4 text-center">{item.title}</div>
              <div className="lh-lg" style={{ fontSize: 13 }}>
                {item.subtitle}
              </div>
              {/* <div className="text-center text-decoration-underline pointer">
                عرض المزيد
              </div> */}
            </div>
          </div>
        ))}
        <div className="col-12 col-md-6 col-lg-4 ">
          <div
            className={`p-3 
              text-right
            }  text-white rounded-3 style-scroll-radius `}
            style={{
              background: "var(--primary)",
              height: 300,
              overflowY: "scroll",
            }}
          >
            <div>
              <div className=" d-flex justify-content-center position-relative">
                <img
                  src="../../images/icon-goal.png"
                  style={{ width: 70, height: 70 }}
                  alt=""
                />
              </div>
            </div>
            <div className="fw-bold my-2 fs-4 text-center">{t("goals")}</div>
            <div className="lh-lg" style={{ fontSize: 13 }}>
              {goals.map((item, i) => (
                <li key={i} className="">
                  {/* <span>{i}</span> */}
                  <span>{item}</span>
                </li>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <HeaderTitle title={"فريق العمل"} />
      <div className="row gy-3 mt-4 d-flex align-items-center justify-content-center">
        {teamsWork.map((item, i) => (
          <div key={i} className="col ">
            <div className="d-flex align-items-center flex-column">
              <div>
                <div
                  style={{
                    border: "3px solid var(--primary)",
                  }}
                  className="rounded-circle p-1"
                >
                  <img
                    src={item.img}
                    style={{ width: 130, height: 130 }}
                    className="rounded-circle"
                    alt=""
                  />
                </div>
              </div>
              <div className="mt-2 fw-bold" style={{ color: "var(--primary)" }}>
                {item.job}
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default AboutDetailsContainer;
