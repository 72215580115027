import React from "react";
import Header from "../../components/home/Header";
import Landing from "../../components/home/Landing";
import CountUp from "../../components/home/CountUp";
import About from "../../components/home/About";
import MyServices from "../../components/home/MyServices";
import Vistion from "../../components/home/Vistion";
import Contact from "../../components/home/Contact";
import Footer from "../../components/home/Footer";
import Products from "../../components/home/Products";
// import Sponser from "../../components/home/Sponser";

const HomePage = () => {
  // const refServices = useRef();
  // const refContact = useRef();

  // const clickRefServices = () => {
  //   refServices.current.scrollIntoView({
  //     block: "center",
  //   });
  // };

  // const clickRefContact = () => {
  //   refContact.current.scrollIntoView({
  //     block: "center",
  //   });
  // };

  return (
    <div className="">
      <Header
      // refScroll={[clickRefServices, clickRefContact]}
      />
      <Landing />
      <CountUp />
      <About />
      <Vistion />
      {/* <div ref={clickRefServices}> */}
      <MyServices />
      <Products />
      {/* <Sponser /> */}
      {/* </div> */}
      {/* {/* <div ref={clickRefContact}> */}
      <Contact />
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default HomePage;
