import React from "react";
import { useTranslation } from "react-i18next";

const Vistion = () => {
  const { t } = useTranslation();

  const data = [
    {
      icon: "../../images/icon-our-task.png",
      title: t("mission"),
      subtitle: t("our-mission"),
    },
    {
      icon: "../../images/vition.png",
      title: t("vision"),
      subtitle: t("our-vision"),
    },
  ];
  const goals = [
    t("our-goals-content.one"),
    t("our-goals-content.two"),
    t("our-goals-content.three"),
    t("our-goals-content.four"),
    t("our-goals-content.five"),
    t("our-goals-content.sex"),
    t("our-goals-content.seven"),
  ];

  return (
    <div className="container mt-5 pt-3">
      <div className="row gy-4">
        {data.map((item, i) => (
          <div key={i} className="col-12 col-md-6 col-lg-4 ">
            <div
              className={`p-3 text-center  text-white rounded-4 style-scroll-radius `}
              style={{
                background: "var(--primary)",
                height: 300,
              }}
            >
              <div>
                <div className=" d-flex justify-content-center position-relative">
                  <img
                    src={item.icon}
                    style={{ width: 70, height: 70 }}
                    alt=""
                  />
                </div>
              </div>
              <div className="fw-bold my-2 fs-4 text-center">{item.title}</div>
              <div className="lh-lg" style={{ fontSize: 13 }}>
                {item.subtitle}
              </div>
              {/* <div className="text-center text-decoration-underline pointer">
                عرض المزيد
              </div> */}
            </div>
          </div>
        ))}
        <div className="col-12 col-md-6 col-lg-4 ">
          <div
            className={`p-3 
              text-right
            }  text-white rounded-4 style-scroll-radius `}
            style={{
              background: "var(--primary)",
              height: 300,
              overflowY: "scroll",
            }}
          >
            <div>
              <div className=" d-flex justify-content-center position-relative">
                <img
                  src="../../images/icon-goal.png"
                  style={{ width: 70, height: 70 }}
                  alt=""
                />
              </div>
            </div>
            <div className="fw-bold my-2 fs-4 text-center">{t("goals")}</div>
            <div className="lh-lg" style={{ fontSize: 13 }}>
              {goals.map((item, i) => (
                <li key={i} className="">
                  {/* <span>{i}</span> */}
                  <span>{item}</span>
                </li>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vistion;
