import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BlogsData } from "./BLogsContainer";
import { useTranslation } from "react-i18next";
// import { dataBlogs } from "./BLogsContainer";

const BlogsDetailsContainer = () => {
  const idRoute = useParams();
  const { t } = useTranslation();

  const { dataBlogs } = BlogsData();

  const [dataBlogsSelected] = useState(dataBlogs[idRoute.id]);

  return (
    <div className="container mt-4" style={{ minHeight: "50vh" }}>
      {/* title */}
      <h1 className="text-center " style={{ color: "var(--primary)" }}>
        {dataBlogsSelected.title}
      </h1>
      {/* description */}
      <div className="mt-4" style={{ color: "var(--gray)" }}>
        {dataBlogsSelected.details}
      </div>
      {/* items */}
      <div className="row gy-3 my-2">
        {dataBlogsSelected.items.map((item, i) => (
          <div key={i} className="col-12 col-sm-6  col-md-4 ">
            <div
              className=" p-2 rounded-3 style-shadow"
              style={{ background: "var(--primary)" }}
            >
              <div
                key={i}
                className="my-2 d-flex flex-column align-items-center"
              >
                <span className="text-center fw-bold" style={{ color: "#fff" }}>
                  {item.title}
                </span>
                {/* <span
                  className=" mt-2 p-2 "
                  style={{ color: "#fff", fontSize: 13 }}
                >
                  {item.items.map((_item, _i) => (
                    <li key={_i} className="text-white">
                      {_item.description === "" ? (
                        <span style={{ fontSize: 13 }}> {_item.title}</span>
                      ) : (
                        <>
                          <span className=" fw-bold"> {_item.title}</span> :
                          <span style={{ opacity: 0.8 }}>
                            {" "}
                            {_item.description}
                          </span>
                        </>
                      )}
                    </li>
                  ))}
                </span> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div>
        {dataBlogsSelected.items.map((item, i) => (
          <div key={i} className="my-2">
            <span className="fw-bold" style={{ color: "var(--primary)" }}>
              {item.title} :{" "}
            </span>
            <span className="" style={{ color: "var(--gray)" }}>
              {item.items.map((_item, _i) => (
                <li key={_i} className="text-dark">
                  {_item.description === "" ? (
                    <span> {_item.title}</span>
                  ) : (
                    <>
                      <span className="text-dark fw-bold"> {_item.title}</span>{" "}
                      :<span> {_item.description}</span>
                    </>
                  )}
                </li>
              ))}
            </span>
          </div>
        ))}
      </div> */}
      {/* goal */}
      {/* <div
        className="my-3 mt-5 text-center"
        style={{ color: "var(--primary)" }}
      >
        {dataBlogsSelected.goal}
      </div> */}

      {/* button join */}
      <div className="d-flex justify-content-center align-items-center mt-5 flex-column">
        {/* <ButtonRoundedFill title={"إنضمام"} width={180} /> */}
        <div className="mt-2">
          <Link
            to={"/blogs"}
            style={{
              color: "var(--primary)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            className="btn-more"
          >
            {t("back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogsDetailsContainer;
