import React, { useRef, useState } from "react";
import HeaderTitle from "../utility/HeaderTitle";
import FormInput from "../utility/FormInput";
import FormInputTextArea from "../utility/FormInputTextArea";
import ButtonRoundedFill from "../utility/ButtonRoundedFill";
import emailjs from "@emailjs/browser";
import Notify from "../utility/Notify";
import ButtonLoading from "../utility/ButtonLoading";
import Select from "react-dropdown-select";
import { useTranslation } from "react-i18next";

const ContactUsContainer = () => {
  const form = useRef();
  const { t } = useTranslation();

  const options = [
    {
      value: 1,
      label: t("Technical consultations"),
    },
    {
      value: 2,
      label: t("ERP-systems-projects"),
    },
    {
      value: 3,
      label: t("cloud-computing"),
    },
    {
      value: 4,
      label: t("Website-design-consultation"),
    },
    {
      value: 5,
      label: t("consultancy-in-building-electronic-applications"),
    },
    {
      value: 6,
      label: t("digita-transformation"),
    },
    {
      value: 7,
      label: t("provider-servies"),
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const [selcetsChoose, setselcetsChoose] = useState("");
  const [loading, setLoading] = useState(false);
  // const [load, setLoad] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_4wm4j8h",
        "template_unn2fei",
        form.current,
        "qvXEW1USUZMOwQxs4"
      )
      .then(
        (result) => {
          setLoading(false);
          Notify("تم إرسال طلب ", "done");
          // console.log(result.text);
        },
        (error) => {
          setLoading(false);
          Notify("هناك مشكلة في إرسال الطلب ", "error");

          // console.log(error.text);
        }
      );
  };

  return (
    <div className="container">
      <HeaderTitle title={t("contact-us")} />
      <div className="mt-4 text-center " style={{ color: "var(--gray)" }}>
        {t("desc-contact-form")}{" "}
      </div>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="col-12 col-lg-8 m-auto mt-4 rounded-3 bg-white d-flex justify-content-center align-items-center flex-column p-3 p-lg-4 style-shadow"
      >
        <div className="col-12 col-sm-10 my-1">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={t("Full-name")}
            name={"from_name"}
            placeholder={t("Full-name")}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12 col-sm-10 my-1">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={t("Phone-number")}
            name={"from_phone"}
            placeholder={t("Phone-number")}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12 col-sm-10 my-1">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={t("Email")}
            name={"from_email"}
            placeholder={t("Email")}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12 col-sm-10 my-1">
          <label
            // htmlFor={idAndfor}
            className="form-label fw-bold "
            style={{ fontSize: "14px" }}
          >
            {t("Choose the service")}
          </label>
          <Select
            // multi
            multi={false}
            options={options}
            onChange={(values) => setselcetsChoose(values)}
            className="text-center"
            name="from_serv"
          />
        </div>
        <div className="col-12 col-sm-10 my-1">
          <FormInputTextArea
            // onChange={handelChangeForms}
            // value={""}
            label={t("Message")}
            placeholder={t("Message")}
            name={"from_msg"}
            idAndfor={"validationCustomIdPrivate"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          {loading ? (
            <ButtonLoading width={180} />
          ) : (
            <ButtonRoundedFill title={t("send")} width={180} />
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactUsContainer;
